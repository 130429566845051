import React from 'react';
import Seo from '../components/Seo';
import DeepDiveLogo from '../components/DeepDiveLogo';
import Footer from '../components/Footer';

const Privacy = () => (
    <>
        <Seo title="DeepDive 360 - Privacy" />
        <header>
            <div className='container'>
                <div className="row justify-content-center">
                    <div className="logo">
                        <DeepDiveLogo />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <h1>Privacy & GDPR Compliance</h1>
                </div>
            </div>
        </header>
        <main>
            <section>
                <h2>We take privacy seriously</h2>
                <p>At DeepDive360, our goal is to enhance your product and service experience with us. When ordering or registering on our site, as appropriate, you may be asked to enter your: name, e-mail address, mailing address or phone number.</p>
            </section>
            <section>
                <h2>What do we use your information for?</h2>
                <p>Any of the information we collect from you may be used in one of the following ways:</p>
                <ul>
                    <li>To personalize your experience (your information helps us to better respond to your individual needs)</li>
                    <li>To improve our website (we continually strive to improve our website based on the information and feedback we receive from you)</li>
                    <li>To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs)</li>
                    <li>To process transactions (Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested.)</li>
                    <li>To administer a contest, promotion, survey or other site feature</li>
                    <li>To send periodic emails and communications. (The email address you provide may be used to send you information, respond to inquiries, and/or other requests or questions.) You can opt out of marketing emails at anytime</li>
                </ul>
                <br/>
            </section>
            <section>
                <h2>How do we protect your information?</h2>
                <p>We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.</p>
                <p>All information pertaining to credit card is processed by Stripe using SSL and will show up on your credit card statement from SkillShark Software Inc.</p>
                <p>Absolutely no information pertaining your credit card will be saved in our database.</p>
            </section>
            <section>
                <h2>Do we use cookies?</h2>
                <p>Yes. We may collect and store information about your visit on an anonymous, aggregate basis using “cookie” technology. A cookie is an element of data that a Web site can send to your browser, which may then store the cookie on your hard drive. Cookies may tell us the time and length of your visit, the pages you look at on our sites, and the site you visited just before coming to ours. We may also record the name of your Internet service provider. This information allows us to measure site activity to tailor the consumer’s experience within our Web site.</p>
                <p>The goal is to save you time and provide you with a more meaningful visit. Cookies used by DeepDive360 on its server are solely to facilitate navigation and security across the site, and are not used to capture or reveal any of your personal information. We use cookies to understand and save your preferences for future visits and compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future for our clients.</p>
                <p>If you are concerned about cookies, you may modify your browser settings to notify you when you receive a cookie. If you do not know how to modify your browser settings please consult your browser documentation or the supplier web site. You may visit the DeepDive360 site with your cookies turned off. However, you may not be able to access some of the areas of our site, which require cookies for customization and personalization. We may also use third parties to serve DeepDive360 advertisements on other Web sites. In serving DeepDive360 advertisements these third parties may place cookies on your browser computer and may collect certain anonymous information about your visits to other Web sites. This information includes browser type, IP address, Web activity and time of day and is used to better understand and optimize DeepDive360’s online advertising campaigns. To opt-out of these cookies, please review the privacy policies of that web site and follow the instructions on their site.</p>
            </section>
            <section>
                <h2>Ads</h2>
                <p>We use Google Analytics, Google AdWords Conversion tracker, and other Google services that place cookies on a browser across the website. These cookies help us increase the website’s effectiveness for our visitors. These cookies are set and read by Google. To opt out of Google tracking, please visit <a target='_blank' rel="noreferrer" href='https://policies.google.com/technologies/ads'>this page</a>.</p>
                <p>We use AdWords remarketing to market our sites across the web. We place a cookie on a browser, and then a 3rd party (Google) reads these cookies and may serve an ad on a 3rd party site. You may opt out of this ad serving on Google’s opt out page. If you are concerned about 3rd party cookies served by networks, you should also visit <a target='_blank' rel="noreferrer" href='https://optout.networkadvertising.org/?c=1'>the Network Advertising Initiative opt-out page</a>.</p>
            </section>
            <section>
                <h2>Remarketing</h2>
                <p>Remarketing is powerful ads used to serve ads across the content network to people who previously visited our website. This is done by using cookies.</p>
            </section>
            <section>
                <h2>Third party links</h2>
                <p>Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</p>
            </section>
            <section>
                <h2>Children’s Online Privacy Protection Act Compliance</h2>
                <p>We are in compliance with the requirements of COPPA (Children’s Online Privacy Protection Act), we do not collect any information from anyone under 13 years of age. Our website, products and services are all directed to people who are at least 13 years old or older.</p>
            </section>
            <section>
                <h2>Online Privacy Policy Only</h2>
                <p>This online privacy policy applies only to information collected through our website and not to information collected offline.</p>
            </section>
             <section>
                <h2>Your Consent</h2>
                <p>By using our site, you consent to our privacy policy.</p>
            </section>
             <section>
                <h2>Changes to our Privacy Policy</h2>
                <p>If we decide to change our privacy policy, we will post those changes on this page.</p>
             </section>
             <section>
                <h2>Contacting Us</h2>
                <p>If there are any questions regarding this privacy policy you may contact us using the information below.</p>
                <address>
                    www.deepdive360.com<br/>
                    129-116 Research Drive<br/>
                    Saskatoon, SK<br/>
                    Canada, S7V0J8<br/>
                    <a href='mailto:support@deepdive360.com'>support@deepdive360.com</a>
                </address>
             </section>
        </main>
        <Footer/>
    </>
);

export default Privacy;
